@import url(https://use.typekit.net/njj6mtl.css);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
/*** The new CSS Reset - version 1.4.2 (last updated 30.11.2021) ***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-width: 100%;
    display: block;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly. */
:where([contenteditable]){
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
}

:root {
  /* --wine-blue: #10445C; */
  /* --wine-blue: #1C5975; */
  --wine-blue: #366F86;
  --wine-green: #3C6E0B;
  --wine-peach: #EEB685;
  --wine-gold: #F4DD74;
  --wine-dark: #671F2D;
  --wine-red: #9C1A21;
  --tone-light: #F2EDE8;
  --tone-dark: #D5CABD;
}

html {
  font-size: 18px;
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: europa, sans-serif;
}/* FIX against reset */

body, h1, h2, h3 {
  font-weight: 300;
}

.display {
  font-family: 'Playfair Display', serif;
}

header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: #F2EDE8;
  color: var(--tone-light);
  background-color: black;
}

h1 {
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

h1.pirouette-logo{
  position: relative;
  text-align: center;
  font-style: normal;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

h1.pirouette-logo .description, h1.pirouette-logo .ig-link {
  position: absolute;
}

h1.pirouette-logo .column {
  position: relative;
  display: inline-block;
}

h1.pirouette-logo span {
  position: relative;
  display: block;
  top: 0;
}

h1.pirouette-logo:hover span {
  -webkit-animation: bounce 0.15s ease-in-out 2 alternate;
          animation: bounce 0.15s ease-in-out 2 alternate;
}

@-webkit-keyframes bounce {
  25% {
    top: 8%;
  }
  100% {
    top: -30%;
  }
}

@keyframes bounce {
  25% {
    top: 8%;
  }
  100% {
    top: -30%;
  }
}

header h1.announcement {
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem;
  color: black;
}

header .announcement.block {
  width: 100%;
  position: relative;
  padding-bottom: 106px;
  background-color: #F2EDE8;
  background-color: var(--tone-light);
}

header .announcement.block .stripes {
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 70px;
}

header .announcement.block .stripes .stripe {
  position: relative;
}

header .announcement.block .stripes .stripe:first-child {
  height: 2px;
  left: 4vw;
  width: 8vw;
  margin-bottom: 2px;
  background-color: #EEB685;
  background-color: var(--wine-peach);
}

header .announcement.block .stripes .stripe:nth-child(2) {
  height: 3px;
  left: 2vw;
  width: 13vw;
  margin-bottom: 3px;
  background-color: #F4DD74;
  background-color: var(--wine-gold);
}

header .announcement.block .stripes .stripe:nth-child(3) {
  height: 5px;
  left: 1vw;
  width: 20vw;
  margin-bottom: 5px;
  background-color: #EEB685;
  background-color: var(--wine-peach);
}

header .announcement.block .stripes .stripe:nth-child(4) {
  height: 8px;
  left: 4vw;
  width: 34vw;
  margin-bottom: 8px;
  background-color: #366F86;
  background-color: var(--wine-blue);
}

header .announcement.block .stripes .stripe:nth-child(5) {
  height: 10px;
  left: 9vw;
  width: 55vw;
  margin-bottom: 13px;
  background-color: #9C1A21;
  background-color: var(--wine-red);
}

header .announcement.block .stripes .stripe:nth-child(6) {
  height: 13px;
  left: 17vw;
  width: 89vw;
  margin-bottom: 8px;
  z-index: 1;
  background-color: #671F2D;
  background-color: var(--wine-dark);
}

header .announcement.block .stripes .stripe:nth-child(7) {
  height: 8px;
  left: 30vw;
  width: 55vw;
  z-index: 1;
  background-color: #3C6E0B;
  background-color: var(--wine-green);
}

.ig-link {
  height: 30px;
  width: 30px;
  background-image: url(/static/media/instagram.6292774a.png);
  background-size: cover;
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  transition: 0s;
}

.ig-link:hover {
  -webkit-filter: hue-rotate(360deg);
          filter: hue-rotate(360deg);
  transition: 0.5s ease-in-out;
}

#content-body {
  position: relative;
  background-color: #F2EDE8;
  background-color: var(--tone-light);
}

h3 {
  font-size: 2.4rem;
}

p {
  font-size: 1.2rem;
}

.image-block {
  position: relative;
  z-index: 0;
}

.image-block figure {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  position: relative;
  width: 100%;
}

.image-block img {
  width: 100%;
  position: relative;
  z-index: 1;
}

.image-block .stripe {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 4px;
  z-index: -1;
}

#content-body > p {
  margin-left: 3rem;
  padding: 1rem;
  text-indent: 1rem;
  line-height: 2rem;
  background: #D5CABD;
  background: var(--tone-dark);
  font-size: 1.6rem;
}

#statement {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0;
  margin-bottom: 3rem;
  background: #D5CABD;
  background: var(--tone-dark);
}

#statement .col-1 {
  width: calc(100% - 1em);
}

#statement .col-1 figure:before {
  width: 100%;
}

#statement .col-2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#statement .col-2 div {
  -webkit-flex: 1 1;
          flex: 1 1;
}

#statement .col-2 p {
  margin-top: -8rem;
  padding: 1rem;
  padding-top: 9rem;
  border-right: 2em solid #F2EDE8;
  border-right: 2em solid var(--tone-light);
  border-left: 1em solid #F2EDE8;
  border-left: 1em solid var(--tone-light);
}

/* EXPERIENCE */

#experience {
  padding-bottom: 2rem;
}

#experience h2 {
  padding: 0 0.25rem;
  padding-bottom: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 2.5rem;
}

#experience h2 span:first-child {
  text-indent: 1rem;
}

#experience h2 span:last-child {
  color: #9C1A21;
  color: var(--wine-red);
}

#experience > .image-block:first-of-type {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#experience > .image-block:first-of-type .image-container:first-child {
  width: calc(100% - 1rem);
  -webkit-align-self: flex-end;
          align-self: flex-end;
  -webkit-order: 1;
          order: 1;
}

#experience > .image-block:first-of-type .image-container:nth-child(2) {
  width: 100%;
  -webkit-order: 3;
          order: 3;
  z-index: 1;
}

#experience > .image-block:first-of-type div.display:nth-child(3) {
  margin: -1rem 1.6rem -1rem 0;
  padding: 2rem 1rem 2rem 1.5rem;
  -webkit-order: 2;
          order: 2;
  font-size: 1.2rem;
  text-align: center;
  background: #D5CABD;
  background: var(--tone-dark);
}

#experience p:nth-child(3) {
  background: #D5CABD;
  background: var(--tone-dark);
  margin: 0.8rem 0.6rem;
  padding: 1.2rem 1.1rem;
}

#experience .image-block:nth-child(4){
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100vw;
  z-index: 1;
}

#experience .image-block:nth-child(4) .image-container:first-child {
  width: 100%;
}

#experience .image-block:nth-child(4) .image-container:nth-child(2) {
  width: 80%;
  margin-top: 2rem;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

#experience .image-block:nth-child(4) .image-container:nth-child(2) figure:before {
  content: '';
  position: absolute;
  width: calc(100% + 16px);
  height: 100%;
  background: #f2ede8;
  z-index: 0;
  top: 16px;
  right: 0;
}

#experience .image-block:nth-child(4) .stripe {
  display: none;
}

#experience .second {
  padding: 20vh 0 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#experience .second h3 {
  z-index: 1;
  padding-bottom: 10px;
}

#experience .second h3 span {
  color: #3C6E0B;
  color: var(--wine-green);
}

#experience .second .image-block {
  top: unset;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#experience .second .image-block .image-containger:first-child {
  -webkit-order: 1;
          order: 1;
}

#experience .second .image-block .image-container:nth-child(2) {
  width: 80%;
  -webkit-order: 3;
          order: 3;
}

#experience .second .image-block .stripe:nth-child(3) {
  width: 80vw;
  height: 265px;
  top: -281px;
  left: -2rem;
  bottom: unset;
  background: #D5CABD;
  background: var(--tone-dark);
}

#experience .second .image-block .stripe:nth-child(4) {
  bottom: -1rem;
  right: calc(-2rem + 30vw);
  left: unset;
  width: 66vw;
  height: 7px;
  background-color: #671F2D;
  background-color: var(--wine-dark);
}

#experience .second .image-block .stripe:nth-child(5) {
  height: 2vh;
  left: unset;
  right: 0;
  width: 94vw;
  bottom: -3rem;
  background-color: #9C1A21;
  background-color: var(--wine-red);
}

#experience .second .image-block p {
  margin: 1rem 0 -1rem 1rem;
  padding: 1rem 1rem 2rem;
  -webkit-order: 2;
          order: 2;
  text-align: center;
  background: #D5CABD;
  background: var(--tone-dark);
}

/* LOCATION */

#location {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  padding-bottom: 10vh;
}

#location h3 {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  background: #F2EDE8;
  background: var(--tone-light);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.2rem;
  text-align: right;
  position: relative;
  top: -10px;
}

#location h3 span:last-child {
  color: #366F86;
  color: var(--wine-blue);
}

#location .image-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#location .image-block .image-container:first-child {
  width: 65%;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  -webkit-order: 2;
          order: 2;
}

#location .image-block .image-container:nth-child(2) {
  width: 80%;
  -webkit-order: 4;
          order: 4;
}

#location .image-block p.tagline {
  width: 35%;
  padding: 1rem 0.5rem;
  position: absolute;
  -webkit-order: 1;
          order: 1;
  font-size: 1.3rem;
}

#location .image-block p.address {
  margin: 1.5rem;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-order: 5;
          order: 5;
}

#location .image-block p.address span {
  text-align: center;
}

#location .image-block p.blurb {
  -webkit-order: 3;
          order: 3;
  margin: -3rem 0.5rem -2.5rem;
  padding: 3.8rem 1rem 3.8rem;
  background: #D5CABD;
  background: var(--tone-dark);
}

#location .image-block .stripe:nth-child(3) {
  height: 23px;
  bottom: -23px;
  background-color: #366F86;
  background-color: var(--wine-blue);
}

#location .image-block .stripe:nth-child(4) {
  height: 17px;
  bottom: -15px;
  background-color: #EEB685;
  background-color: var(--wine-peach);
}

#location .image-block .stripe:nth-child(5) {
  height: 28px;
  bottom: -41px;
  background-color: #9C1A21;
  background-color: var(--wine-red);
}

#location .image-block .stripe:nth-child(6) {
  height: 10px;
  bottom: -49px;
  background-color: #671F2D;
  background-color: var(--wine-dark);
}

/* ABOUT */

#about h3 span {
  font-weight: 400;
  color: #9C1A21;
  color: var(--wine-red);
}

#about p {
  padding: 2rem;
}

#about .first .image-container {
  padding: 16px 0;
  padding-bottom: 0;
}

#about .second {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#about .second .title {
  font-size: 2rem;
  padding: 1rem;
}

#about .second .title span.orange {
  color: #9C1A21;
  color: var(--wine-red);
}

#about .second .image-container:first-child {
  -webkit-order: 1;
          order: 1;
}

#about .second .title:nth-child(3) {
  padding: 0 1rem;
  margin-top: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-order: 2;
          order: 2;
}

#about .second .title:nth-child(3) span:last-child {
  text-align: center;
}

#about .second p:nth-child(4) {
  position: relative;
  top: calc(-1.5 * (50vw - 2em) + 165px);
  padding: 1rem;
  padding-bottom: 1.5rem;
  -webkit-order: 3;
          order: 3;
  font-size: 1rem;
  background: #D5CABD;
  background: var(--tone-dark);
}

#about .second .image-container:nth-child(2) {
  -webkit-order: 4;
          order: 4;
  margin-top: 1rem;
}

#about .second .title:nth-child(5) {
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-order: 5;
          order: 5;
}

#about .second .title:nth-child(5) p {
  padding: 0;
  font-size: 2rem;
  text-align: center;
}

#about .second p:nth-child(6) {
  padding: 1.5rem 2rem 0;
  background: #D5CABD;
  background: var(--tone-dark);
  -webkit-order: 6;
          order: 6;
}

/* NEWSLETTER */

#newsletter {
  padding-top: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

input, button {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: 0.03rem;
}

#newsletter .col-1 {
  margin: 1rem 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 10px solid black;
  background: #F2EDE8;
  background: var(--tone-light);
}

#newsletter .col-2 {
  width: 90vw;
  height: 100vw;
  left: 5vw;
  overflow: hidden;
}

#newsletter .col-2 .image-container {
  height: 100%;
}

#newsletter .col-2 .image-container figure {
  height: 100%;
}

#newsletter .col-2 .image-container figure img {
  position: absolute;
  top: -40%;
}

#newsletter .email-cta {
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem 0.5rem 0;
}

#newsletter .email-fields {
  margin: 2em 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

#newsletter .email-fields input {
  color: #671F2D;
  color: var(--wine-dark);
  caret-color: #9C1A21;
  caret-color: var(--wine-red);
  padding: 1rem 0.5rem;
  border: 1px solid #D5CABD;
  border: 1px solid var(--tone-dark);
  outline: none;
  transition: all 0.15s ease-in-out;
}

#newsletter .email-fields input:focus {
  border-color:#c9c9c9;
  background-color: #fff;
}

#newsletter .email-fields button {
  min-height: 3rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 16px 16px 0;
  padding: 0 1rem;
  border: 3px solid #9C1A21;
  border: 3px solid var(--wine-red);
  -webkit-flex: 0 0 fit-content;
          flex: 0 0 fit-content;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  background-color: #F2EDE8;
  background-color: var(--tone-light);
  color: #671F2D;
  color: var(--wine-dark);
}

#newsletter .email-fields button:hover {
  color: #F2EDE8;
  color: var(--tone-light);
  background-color: #9C1A21;
  background-color: var(--wine-red);
}

#newsletter .email-footnote {
  text-align: center;
  padding-bottom: 0.5rem;
}

footer {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem;
  letter-spacing: 0.03rem;
  font-size: 1.15rem;
  color: #D5CABD;
  color: var(--tone-dark);
  background-color: #000;
}

footer a {
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 1rem;
  border-bottom: 1px solid #F2EDE8;
  border-bottom: 1px solid var(--tone-light);
  transition: all 0.15s ease-in-out;
}

footer a:hover {
  color: white;/* FIX test if this looks enough */
  border-color: white;
}

/* RESPONSIVE DESIGN */

/* larger mobile phones */
@media only screen and (min-width: 576px) {
  .ig-link {
    height: 50px;
    width: 50px;
  }

  header h1.announcement {
    font-size: 1.5rem;
  }

  .section {
    padding: 2rem;
  }

  .image-block figure::before {
    content: '';
    position: absolute;
    width: calc(100% + 16px);
    height: 100%;
    background: #f2ede8;
    z-index: 0;
  }

  #statement {
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 0 0 2rem 0;
  }

  #statement .col-1 {
    width: unset;
    -webkit-flex: 0 0 70%;
            flex: 0 0 70%;
  }

  #statement .col-1 .stripe {
    bottom: -2rem;
    width: 18vw;
    height: 0.3rem;
    background-color: #9C1A21;
    background-color: var(--wine-red);
  }

  #statement .col-2 p {
    margin-top: 0;
    margin-bottom: 3rem;
    padding-top: 1rem;
    border: none;
  }

  #experience h2 {
    padding-bottom: 3rem;
    border: 10px solid #F2EDE8;
    border: 10px solid var(--tone-light);
    border-left: 10px solid black;
    border-bottom: 10px solid #D5CABD;
    border-bottom: 10px solid var(--tone-dark);
    margin-left: calc(60% - 4px);
    position: relative;
    top: 26px;
    z-index: 1;
  }

  #experience h2 span:first-child {
    text-indent: 0;
  }

  #experience > .image-block:first-of-type {
    top: -60px;
  }

  #experience > .image-block:first-of-type .image-container {
    position: relative;
  }

  #experience > .image-block:first-of-type .image-container:first-child {
    width: 60%;
    left: -2rem;
    top: -2rem;
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }

  #experience > .image-block:first-of-type .image-container:first-child:before {
    content: '';
    width: calc(100% + 32px);
    position: absolute;
    height: 100%;
    top: -26px;
    background-color: #F2EDE8;
    background-color: var(--tone-light);
  }

  #experience > .image-block:first-of-type .image-container:first-child figure:before {
    width: calc(100% + 16px);
    top: 16px;
  }

  #experience > .image-block:first-of-type .image-container:nth-child(2) {
    width: 80%;
    right: -2rem;
    top: -140px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    z-index: 1;
  }

  #experience > .image-block:first-of-type .image-container:nth-child(2) figure:before {
    width: calc(100% + 16px);
    right: 0;
    bottom: 16px;
  }

  #experience > .image-block:first-of-type .content {
    width: 100%;
    height: calc(100% - 140px);
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  #experience > .image-block:first-of-type .content div:first-child {
    width: calc(40% + 4px);
    -webkit-align-self: flex-end;
            align-self: flex-end;
    padding: 20px;
    padding-bottom: 0;
    border-top: 10px solid #D5CABD;
    border-top: 10px solid var(--tone-dark);
    margin-top: 86px;
    text-align: center;
    font-size: 1.4rem;
    background: #D5CABD;
    background: var(--tone-dark);
  }

  #experience > .image-block:first-of-type .content div:first-child div {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    height: 1px;
    margin-top: 30px;
    border: 10px solid #F2EDE8;
    border: 10px solid var(--tone-light);
    border-top: 10px solid black;
    background: #F2EDE8;
    background: var(--tone-light);
  }

  #experience p:nth-child(3) {
    margin: 0;
    padding: 2.5rem;
    position: relative;
    top: -135px;
  }

  #experience p:nth-child(3):after {
    content: '';
    background: #D5CABD;
    background: var(--tone-dark);
    position: absolute;
  }

  #experience .image-block:nth-child(4){
    left: -2rem;
    z-index: 0;
  }

  #experience .image-block:nth-child(4) .image-container:first-child {
    width: 75%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    z-index: 2;
  }

  #experience .image-block:nth-child(4) .image-container:first-child figure:before{
    width: calc(100% + 16px);
    right: 0;
    top: 16px;
    margin-top: 0;
  }

  #experience .image-block:nth-child(4) .image-container:nth-child(2) {
    width: 45%;
    position: relative;
    top: -170px;
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }

  #experience .image-block:nth-child(4) .stripe {
    display: block;
  }

  #experience .image-block:nth-child(4) .stripe:nth-child(3) {
    bottom: 230px;
    background-color: #366F86;
    background-color: var(--wine-blue);
  }

  #experience .image-block:nth-child(4) .stripe:nth-child(4) {
    bottom: 190px;
    height: 25px;
    background-color: #EEB685;
    background-color: var(--wine-peach);
  }

  #experience .image-block:nth-child(4) .stripe:nth-child(5) {
    right: 0;
    left: unset;
    width: 40%;
    bottom: 168px;
    height: 13px;
    background-color: #3C6E0B;
    background-color: var(--wine-green);
  }

  #experience .image-block:nth-child(4) .stripe:nth-child(6) {
    bottom: 120px;
    height: 8px;
    width: 90vw;
    background-color: #9C1A21;
    background-color: var(--wine-red);
  }

  #experience .image-block:nth-child(4) .stripe:nth-child(7) {
    width: 17vw;
    height: 7px;
    background-color: #EEB685;
    background-color: var(--wine-peach);
  }

  #experience .image-block:nth-child(4) .stripe:nth-child(8) {
    top: -70px;
    left: unset;
    right: 0;
    height: 32px;
    width: 91vw;
    background-color: #671F2D;
    background-color: var(--wine-dark);
  }

  #experience .second {
    padding: 20vh 0;
  }

  #experience .second .image-block {
    padding-top: 20px;
  }

  #experience .second .image-block .image-container {
    z-index: 1;
    -webkit-order: unset;
            order: unset;
  }

  #experience .second .image-block .image-container figure:before {
    top: 16px;
  }

  #experience .second .image-block .image-container:nth-child(2) {
    width: 70%;
    position: relative;
    top: 2rem;
  }

  #experience .second .image-block .image-container:nth-child(2) figure {
    left: -2rem;
  }

  #experience .second .image-block .stripe:nth-child(3) {
    width: 65vw;
  }

  #experience .second .image-block .stripe:nth-child(4) {
    bottom: -30vh;
  }

  #experience .second .image-block .stripe:nth-child(5) {
    width: 84vw;
    bottom: -27vh;
  }

  #experience .second .image-block .content {
    width: 60%;
    height: calc(100% + 88px);
    -webkit-align-self: flex-end;
            align-self: flex-end;
    position: absolute;
    top: 0;
    right: -2rem;
    background: #D5CABD;
    background: var(--tone-dark);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  #experience .second .image-block .content p {
    width: calc(50% + 3rem);
    margin-left: auto;
    margin-bottom: calc(0.75 * 25vw);
    padding-right: 1rem;
    text-align: center;
  }

  #location {
    padding-bottom: 5vh;
  }

  #location .image-block .image-container {
    -webkit-order: unset;
            order: unset;
  }

  #location .image-block .image-container:first-child {
    width: 48%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    z-index: 2;
  }

  #location .image-block .image-container:first-child figure {
    right: -2rem;
  }

  #location .image-block .image-container:first-child figure:before {
    right: 0;
    top: 16px;
  }

  #location .image-block .image-container:nth-child(2) {
    width: 68%;
    position: relative;
    top: -140px;
  }

  #location .image-block .image-container:nth-child(2) figure {
    left: -2rem;
  }

  #location .image-block .image-container:nth-child(2) figure:before {
    bottom: 16px;
  }

  #location .image-block .content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  #location .image-block .content .tagline {
    width: calc(52% + 20px);
    text-align: left;
    font-size: 2rem;
  }

  #location .image-block .content .address {
    width: 52%;
    margin-left: 10px;
    padding-top: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }

  #location .image-block .content .blurb {
    width: 38%;
    position: relative;
    top: 50%;
    right: -31px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
  }

  #location .image-block .stripe {
    left: -2rem;
  }

  #location .image-block .stripe:nth-child(3) {
    bottom: 0;
  }

  #about .image-container figure:before {
    width: 100%;
  }

  #about .first .image-container {
    position: relative;
    width: calc(100% + 2rem);
    left: -2rem;
  }

  #about .first .content p {
    text-align: center;
    font-size: 1.6rem;
    padding: 1rem;
  }

  #about .first .content span {
    font-weight: 400;
  }

  #about .first .content span:first-child {
    color: #003DA6;
  }

  #about .first .content span:nth-child(2) {
    color: #F57F2A;
  }

  #about .first .content span:nth-child(3) {
    color: #008E96;
  }

  #about .second {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  #about .second .image-container:first-child {
    width: 50%;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    position: relative;
    top: 16px;
  }

  #about .second .image-container:first-child figure:before{
    background: #D5CABD;
    background: var(--tone-dark);
    top: -16px;
    left: -16px;
  }

  #about .second .title:nth-child(3) {
    width: 50%;
    position: absolute;
    right: 0;
  }

  #about .second p:nth-child(4) {
    font-size: 1.1rem;
  }

  #about .second p:nth-child(4) span.box {
    width: calc(50vw - 20px);
    height: calc(1.5 * (50vw - 2em) - 133px);
    position: relative;
    top: -1rem;
    left: -1rem;
    margin-bottom: -16px;
    float: left;
    background: #F2EDE8;
    background: var(--tone-light);
  }

  #about .second .image-container:nth-child(2) {
    width: 75%;
    height: 0;
    position: relative;
    margin: 0;
    top: calc(-1.5 * (50vw - 2rem) + 149px);
    right: -2rem;
    -webkit-order: 5;
            order: 5;
  }

  #about .second .image-container:nth-child(2) figure:before {
    width: calc(100% - 20px);
    bottom: 16px;
    left: -16px;
    background: #D5CABD;
    background: var(--tone-dark);
  }

  #about .second .title:nth-child(5) {
    width: 25%;
    height: calc(0.6667 * (75vw - 54px));
    position: relative;
    top: calc(-1.5 * (50vw - 2em) + 149px);
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-order: 4;
            order: 4;
  }

  #about .second .title:nth-child(5) p {
    padding: 0.5rem;
    padding-bottom: 1rem;
    text-align: right;
  }

  #about .second p:nth-child(6) {
    position: relative;
    top: calc(-1.5 * (50vw - 2rem) + 149px);
    padding: 1.5rem 2rem;
    -webkit-order: 6;
            order: 6;
  }

  #newsletter {
    height: calc(100vw - 72px - (1.5 * (50vw - 2rem) - 149px));
    position: relative;
    top: calc(-1.5 * (50vw - 2rem) + 149px);
    padding: 0 1rem;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  #newsletter .col-1, #newsletter .col-2 {
    -webkit-flex: 0 1 49%;
            flex: 0 1 49%;
  }

  #newsletter .col-1 {
    height: calc(0.98 * (100vw - 72px));
    margin: 0;
    margin-right: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: 10px solid black;
    background: #F2EDE8;
    background: var(--tone-light);
  }

  #newsletter .col-2 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: unset;
  }

  #newsletter .col-2 .image-container figure img {
    position: relative;
    top: unset;
  }

  #newsletter .col-2 .image-container figure:before {
    width: 100%;
  }

  #newsletter .email-fields input {
    padding: 1rem 1.5rem;
  }

  #newsletter .email-footnote {
    padding: 0;
  }
}

/* tablets */
@media only screen and (min-width: 768px) {}

/* laptops */
@media only screen and (min-width: 992px) {}

/* desktops */
@media only screen and (min-width: 1200px) {}
